import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'

import styles from './style.module.sass'
import cx from 'classnames'

import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'

export default ({ restoringMasculinity }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title>{restoringMasculinity.title}</Title>

          <Content classes={styles.content} tag="div">
            {restoringMasculinity.paragraphs &&
              restoringMasculinity.paragraphs.map((paragraph, index) => {
                return (
                  <React.Fragment key={paragraph._key}>
                    <p>{prepareParagraph(paragraph.text)}</p>
                  </React.Fragment>
                )
              })}
          </Content>
        </Item>
      </Wrapper>

      {restoringMasculinity.items &&
        restoringMasculinity.items.map((part, index) => {
          const content = (
            <Item classes={cx(styles.contentContainer, styles.part)}>
              <Title blueLine={false} classes={styles.headline}>
                {part.title}
                <span className={styles.subTitle}>{part.subtitle}</span>
              </Title>
              {part.paragraphs &&
                part.paragraphs.map(paragraph => {
                  return (
                    <React.Fragment key={paragraph._key}>
                      <Content classes={styles.partContent} tag="div">
                        {prepareParagraph(paragraph.text)}
                      </Content>
                    </React.Fragment>
                  )
                })}
            </Item>
          )

          const image = (
            <Item classes={cx(styles.contentContainer, styles.imageBlock)}>
              <img className={styles.image} {...srcSetProps(sanityImageUrl(part.image))} alt={part.image?.caption} />
            </Item>
          )

          return (
            <React.Fragment key={part._key}>
              <Wrapper classes={styles.wrapper}>
                {index % 2 == 0 && (
                  <>
                    {content}
                    {image}
                  </>
                )}
                {index % 2 == 1 && (
                  <>
                    {image}
                    {content}
                    {index == 1 && <div className={styles.halfBlue} />}
                  </>
                )}
              </Wrapper>
            </React.Fragment>
          )
        })}
    </Container>
  )
}
