import React from 'react'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ children, classes, blueLine = true }) => (
  <div
    className={cx(styles.block, classes, {
      [styles.blueLine]: blueLine,
    })}
  >
    {children}
  </div>
)
