import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import VideoContainer from '../components/AboutUsPage/VideoContainer'
import DeclineMasculinity from '../components/AboutUsPage/DeclineMasculinity'
import BlameForDecline from '../components/AboutUsPage/BlameForDecline'
import RestoringMasculinity from '../components/AboutUsPage/RestoringMasculinity'
import References from '../components/AboutUsPage/References'

export default ({ pageContext }) => {
  const { data } = pageContext

  return (
    <Layout data={data} heroBodyStyle={{ paddingBottom: '7rem' }}>
      <SEO {...data.seo} />
      <VideoContainer videoUrl={data.videoUrl} />
      <DeclineMasculinity declineMasculinity={data.declineMasculinity} />
      <BlameForDecline blameForDecline={data.blameForDecline} />
      <RestoringMasculinity restoringMasculinity={data.restoringMasculinity} />
      <References references={data.references} />
    </Layout>
  )
}
