import React from 'react'

import cx from 'classnames'
import styles from './style.module.css'

export default ({ classes, videoSrc }) => {
  const src =
    videoSrc || 'https://player.vimeo.com/video/562087606?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

  return (
    <section className={cx(styles.block, classes)}>
      <div className={styles.container}>
        <div>
          <iframe
            src={src}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Maximus Protocol"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </section>
  )
}
