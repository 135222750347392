import React from 'react'
import cx from 'classnames'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'

import styles from './style.module.sass'

import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'

export default ({ declineMasculinity }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer)}>
          <Title>{declineMasculinity.title}</Title>

          <Content classes={styles.content} tag="div">
            {declineMasculinity.paragraphs &&
              declineMasculinity.paragraphs.map((paragraph, index) => {
                return (
                  <React.Fragment key={paragraph._key}>
                    <p>{prepareParagraph(paragraph.text)}</p>
                  </React.Fragment>
                )
              })}

            <img
              className={styles.image}
              {...srcSetProps(sanityImageUrl(declineMasculinity.image))}
              alt={declineMasculinity.image?.caption}
            />
          </Content>
        </Item>
      </Wrapper>
    </Container>
  )
}
