import React, { useEffect } from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'

import styles from './style.module.sass'

import { prepareParagraph } from '../../../utils/format'

export default ({ references }) => {
  useEffect(() => {
    const autorefLinks = document.getElementsByClassName('autoref')
    Array.from(autorefLinks).forEach((item, index) => {
      item.href = item.href.replace('[AUTOREF_COUNT]', index + 1)
      item.innerText = index + 1
    })
  }, [])

  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title blueLine={false} classes={styles.heading}>
            {references.title}
          </Title>
          <Content classes={styles.paragraph} tag="div">
            <ol className={styles.list}>
              {references.items &&
                references.items.map((item, index) => (
                  <li key={item._key}>
                    <div className={styles.anchor} id={`reference_${index + 1}`} />
                    {prepareParagraph(item.text)}
                  </li>
                ))}
            </ol>
          </Content>
          <Content classes={styles.content}>{references.description}</Content>
        </Item>
      </Wrapper>
    </Container>
  )
}
