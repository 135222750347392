import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import VideoPlayer from '../Video'

import styles from './style.module.sass'

export default ({ videoUrl }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <VideoPlayer classes={styles.videoPlayer} videoSrc={videoUrl} />
        </Item>
      </Wrapper>
    </Container>
  )
}
